@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-family: 'Poppins', sans-serif;
}

body {
    /* background-image: url('images/back.jpg'); */


}